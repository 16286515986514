import PropTypes from "prop-types";
import TitleUnderlined from "utils/components/TitleUnderlined";
import LoadingMessage from "utils/components/LoadingMessage";
import { H1_TAG, H2_TAG } from "utils/components/TitleUnderlined/constants";
import { Query } from "react-apollo";
import officesQuery from "modules/about/SpradlingInTheWorldPage/components/OfficesList/query";
import { FormattedMessage } from "react-intl";
import { OFFICES_BUSINESS } from "modules/about/SpradlingInTheWorldPage/components/OfficesList/constants";
import {
  getCurrentLanguageAndInstanceCode,
  getInstanceLink,
  isLATAM,
  isMARINE,
  isMEXICO,
  isUSA,
} from "services/instances";
import BusinessOfficeName from "./components/BusinessOfficeName";

function OfficesListSection({ business, officesByBusiness }) {
  // This is a hotfix because the SpradlingInTheWorld component is not rendering properly when the lang is "es"
  // The right way to do this is by retrieving always the key (immutable) instead of the value (translated) from the server
  // TODO remove this code from here and do the change in the back-end
  let deprecate__businessInEnglish = business;
  let deprecate__businessInSpanish = business;
  if (deprecate__businessInEnglish === "EUROPA")
    deprecate__businessInEnglish = "EUROPE";
  if (
    deprecate__businessInSpanish === "EUROPE" &&
    getCurrentLanguageAndInstanceCode()[1] !== "us" &&
    getCurrentLanguageAndInstanceCode()[1] !== "sm"
  )
    deprecate__businessInSpanish = "EUROPA";

  return (
    <div className="offices-list-section mb-4">
      <TitleUnderlined
        hTag={H2_TAG}
        className="text-size-h1 text-size-sm-h1 text-center text-weight-bold mt-5 mb-5"
      >
        <FormattedMessage {...OFFICES_BUSINESS[deprecate__businessInEnglish]} />
        &nbsp;&nbsp;
        <small>
          <a
            href={getInstanceLink(deprecate__businessInEnglish)}
            className="very-dark-cyan"
          >
            <i className="fa fa-external-link" />
          </a>
        </small>
      </TitleUnderlined>
      <div className="row">
        {officesByBusiness[deprecate__businessInSpanish] &&
          officesByBusiness[deprecate__businessInSpanish]?.map((business) => {
          
            if (!business) return null

            return (
            <div key={business.office.id} className="col-12 col-md-4 mb-5">
              <div className="office-card">
                <h3 className="office-card__name text-size-h3 mb-4">
                  <BusinessOfficeName officeName={business.office.name} />
                </h3>
                <p className="office-card__city mb-0 gray">
                  <strong>
                    {business?.office?.id === 'T2ZmaWNlTm9kZToyMw==' ? business.office.city?.region?.name : business.office.city.name},{" "}
                    {business.office.city.country.name}
                  </strong>
                </p>
                <p className="office-card__address mb-0 gray">
                  <strong>
                    <FormattedMessage
                      id="OfficesList.cardAddress"
                      description="Address in office card at offices list"
                      defaultMessage="Dirección"
                    />
                    :&nbsp;
                  </strong>
                  {business.office.address}
                </p>
                <p className="office-card__phone mb-0 gray">
                  <strong>
                    <FormattedMessage
                      id="OfficesList.cardPhone"
                      description="Phone in office card at offices list"
                      defaultMessage="Teléfono"
                    />
                    :&nbsp;
                  </strong>
                  {business.office.phone}
                </p>

                {
                  // Custom Emails for costa Rica
                  (business?.office?.allEmails?.length > 1 && business?.office?.id === 'T2ZmaWNlTm9kZToyMw==' ) ? 
                  <>
                    <p className="office-card__email mb-0 gray d-flex flex-wrap">
                      <strong>
                        <FormattedMessage
                          id="OfficesList.cardEmailCommercialContact"
                          description="Commercial contact in office card at offices list"
                          defaultMessage="Commercial contact"
                        />
                        :&nbsp;
                      </strong>
                      <a
                        href={`mailto:${business.office.allEmails?.[0]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="a-inherit"
                      >
                        {business.office.allEmails?.[0]}
                      </a>
                    </p>

                    <p className="office-card__email mb-0 gray d-flex flex-wrap">
                      <strong>
                        <FormattedMessage
                          id="OfficesList.cardEmailGeneralManager"
                          description="General Manager in office card at offices list"
                          defaultMessage="General Manager"
                        />
                        :&nbsp;
                      </strong>
                      <a
                        href={`mailto:${business?.office?.allEmails?.[1]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="a-inherit"
                      >
                        {business?.office?.allEmails?.[1]}
                      </a>
                    </p>
                  </>
                  :
                  <p className="office-card__email mb-0 gray">
                    <strong>
                      <FormattedMessage
                        id="OfficesList.cardEmail"
                        description="Email in office card at offices list"
                        defaultMessage="Email"
                      />
                      :&nbsp;
                    </strong>
                    <a
                      href={`mailto:${business.office.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="a-inherit"
                    >
                      {business.office.email}
                    </a>
                  </p>
                }
              </div>
            </div>
          )}
          )}
      </div>
    </div>
  );
}

// TODO: componentization
const OfficesList = () => {
  return (
    <div className="office-list">
      <div className="container">
        <TitleUnderlined
          hTag={H1_TAG}
          className="text-size-h1 text-size-sm-h1 text-center text-weight-bold mt-5 mb-5"
        >
          <FormattedMessage
            id="OfficesList.title"
            description="Spradling in the world title"
            defaultMessage="Spradling en distribuidores en el mundo"
          />
        </TitleUnderlined>
        <p>
          <FormattedMessage
            id="OfficesList.text"
            description="Spradling in the world text"
            defaultMessage="Somos una compañía global con operación comercial y logística en algunas de las principales ciudades del mundo."
          />
        </p>
      </div>
      <div className="container">
        <Query
          query={officesQuery}
          variables={{
            orderBy: "business",
          }}
        >
          {({ data }) => {
            if (data) {
              const { offices } = data;
              const officesByBusiness = offices.edges
                .map((office) => ({
                  business: office.node.business,
                  office: office.node,
                }))
                .reduce((r, a) => {
                  r[a.business] = r[a.business] || [];
                  r[a.business].push(a);
                  return r;
                }, Object.create(null));

              // TODO do this like a programmer
              let keys;
              if (isUSA() || isMARINE())
                keys = ["USA", "EUROPE", "MEXICO", "LATAM"];
              else if (isLATAM()) keys = ["LATAM", "USA", "EUROPE", "MEXICO"];
              else if (isMEXICO()) keys = ["MEXICO", "USA", "LATAM", "EUROPE"];
              else keys = ["EUROPE", "USA", "LATAM", "MEXICO"];

              return keys.map((key, index) => {
                return (
                  <OfficesListSection
                    key={index}
                    business={key}
                    officesByBusiness={officesByBusiness}
                  />
                );
              });
            }

            return <LoadingMessage />;
          }}
        </Query>
      </div>
    </div>
  );
};

OfficesListSection.propTypes = {
  business: PropTypes.shape({
    office: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      allEmails: PropTypes.array,
      city: PropTypes.shape({
        name: PropTypes.string,
        country: PropTypes.shape({
          name: PropTypes.string,
        }),
        region: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    }),
  }),
  officesByBusiness: PropTypes.array,
};

export default OfficesList;
