import PropTypes from "prop-types";

import {
  BACKGROUND_IMAGE_CARD_TYPE,
  COMMON_CARD_TYPE,
  FEATURED_CARD_TYPE,
} from "modules/blog/PostsList/components/BlogPostCard/constants";

import "./styles/BlogPostCard.scss";
import OnlyImageBlogPostCard from "modules/blog/PostsList/components/BlogPostCard/components/OnlyImageBlogPostCard";
import FeaturedBlogPostCard from "modules/blog/PostsList/components/BlogPostCard/components/FeaturedBlogPostCard";
import CommonBlogPostCard from "modules/blog/PostsList/components/BlogPostCard/components/CommonBlogPostCard";

const BlogPostCard = ({ post, loading = false }) => {
  // Enable hover shadow when the loading is false
  const blogPostCardHoverClassname = loading ? "" : "blog-post-card_hover"
  const blogPostCardClassname = `blog-post-card ${blogPostCardHoverClassname}`

  switch (post.layoutType) {
    case BACKGROUND_IMAGE_CARD_TYPE:
      return (
        <div className={blogPostCardClassname}>
          <OnlyImageBlogPostCard loading={loading} post={post} />
        </div>
      );
    case FEATURED_CARD_TYPE:
      return (
        <div className={blogPostCardClassname}>
          <FeaturedBlogPostCard loading={loading} post={post} />
        </div>
      );
    case COMMON_CARD_TYPE:
      // This case correspond to common cards (with or without images)
      // const COMMON_CARD_TYPE
      return (
        <div className={blogPostCardClassname}>
          <CommonBlogPostCard loading={loading} post={post} />
        </div>
      );
    default:
      return null;
  }
};

BlogPostCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string,
    layoutType: PropTypes.oneOf([
      BACKGROUND_IMAGE_CARD_TYPE,
      FEATURED_CARD_TYPE,
      COMMON_CARD_TYPE,
    ]),
    instanceTag: PropTypes.string,
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default BlogPostCard;
