import PropTypes from "prop-types";

// Component TO render office name
const BusinessOfficeName = ({ officeName }) => {
	const splitOfficeName = officeName.split("");

	// Extract registered trade mark of business office name and wrap it in a sup element
	const officeNameWithRegistereMarked = splitOfficeName.map((officeLetter, index) => {
		if (officeLetter === '®') return <sup key={index}>®</sup>;

		return officeLetter;
	});

  return <>{officeNameWithRegistereMarked}</>;
}

BusinessOfficeName.propTypes = {
	officeName: PropTypes.string
}

export default BusinessOfficeName