import gql from "graphql-tag";

const officesQuery = gql`
    query officesByCountryQuery(
        $orderBy: String
    ){
        offices(orderBy: $orderBy) {
            edges {
                node {
                    id
                    name
                    address
                    business
                    latitude
                    longitude
                    phone
                    email
                    allEmails
                    city {
                        id
                        name
                        region {
                            id
                            name
                        }
                        country {
                            id
                            name
                        }
                    }
                }
            }
        }
    }

`;

export default officesQuery;
